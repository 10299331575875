import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { homeApi } from "../API";
import { Form, Row, Col, Table } from "react-bootstrap";
import { getFormattedDate } from "../common/DateHelper";
import "./AppealsDetails.css";
import { getCaseDenialReason } from "../API/home";

const AppealsCaseDetailInfoTable = ({ caseDetails }) => {
  return (
    <React.Fragment>
      <div className="table-case-info">
        <Table
          className=" table-case-info table-div"
          id="responsive-table-case"
        >
            <thead className="table-case-info thead-detail">
              <tr>
                <th>Request Number</th>
                <th>Deficiency Date</th>
                <th>Deficiency Description</th>
                <th>Collector Name</th>
                <th>Deficiency Note</th>
              </tr>
            </thead>
            <tbody style={{ verticleAlign: "top" }}>
              <tr>
                <td>{caseDetails?.requestNumber}</td>
                <td>
                  {getFormattedDate(
                    caseDetails?.majorDeficiency?.deficiencyDate
                  )}
                </td>
                <td>{caseDetails?.majorDeficiency?.deficiencyDescription}</td>
                <td>
                  {caseDetails?.majorDeficiency?.CollectorFirstName}{" "}
                  {caseDetails?.majorDeficiency?.CollectorLastName}
                </td>
                <td>{caseDetails?.majorDeficiency?.DenialReasonNote}</td>
              </tr>
            </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default AppealsCaseDetailInfoTable;
