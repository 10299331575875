import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { homeApi } from "../API";
import { Form, Row, Col } from "react-bootstrap";
import "./AppealsDetails.css";
import AppealsCaseDetailInfoTable from "./AppealsCaseDetailInfoTable";
import AppealsCaseDetailInfo from "./AppealsCaseDetailInfo";

const AppealsCaseDetailAppeal = ({
  caseDetailsAppeal,
  caseAppealLevels,
  caseCollector,
  caseFormConfig,
  sd_value,
  handleChange
}) => {
  return (
    <React.Fragment>
      <div className="table-case-info table-responsive" style={{overflow: 'hidden'}}>
        <h3 className="heading-denial">Appeal</h3>
        <Form className="appeal-form">
          <Row className="row-appeals">
            <Col xs lg="6" className="p-0">
              <Form.Group as={Row} controlId="formAppealLevel">
                <Form.Label column sm={4}>
                  Appeal Level:
                </Form.Label>
                <Col sm={6} className="appealLevel">
                  <Form.Select
                    disabled={
                      !caseFormConfig.find(
                        (c) => c.screeningDecision == sd_value
                      ).isAppealLevelActive
                    }
                    onChange={(e) => handleChange(e,'complexityTypeId')}
                    value={
                      caseDetailsAppeal?.complexityTypeId !== null
                        ? caseAppealLevels?.find(
                            (c) => c.id == caseDetailsAppeal?.complexityTypeId
                          )?.id
                        : ""
                    }
                  >
                    {caseAppealLevels?.map((appealLevel) => (
                      <option key={appealLevel.id} value={appealLevel.id}>
                        {appealLevel.description}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                controlId="formUnlinkelyToOverturn"
                className="pt-2"
              >
                <Form.Label column sm={4}>
                  Unlinkely to Overturn:
                </Form.Label>
                <Col sm={6} className="unlinkelyToOverturn">
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => handleChange(e,'sucessUnlikely')}
                    disabled={
                      !caseFormConfig.find(
                        (c) => c.screeningDecision == sd_value
                      )?.isAppealLevelActive
                    }
                    defaultChecked={caseDetailsAppeal?.sucessUnlikely}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formEmailTo" className="pt-2">
                <Form.Label column sm={4}>
                  Email To:
                </Form.Label>
                <Col sm={6} className="emailTo">
                  <Form.Select
                    disabled={
                      !caseFormConfig.find(
                        (c) => c.screeningDecision == sd_value
                      ).isAppealLevelActive
                    }
                    onChange={(e) => handleChange(e,'collectorUserId')}
                    value ={
                      caseDetailsAppeal?.collectorUserId !== null
                        ? caseCollector?.find(
                            (c) => c.id == caseDetailsAppeal?.collectorUserId
                          )?.id
                        : ""
                    }
                  >
                    {caseCollector?.map((collector) => (
                      <option key={collector.id} value={collector.id}>{collector.fullName}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
            <Col xs lg="6" className="subject">
              <Form.Group
                as={Row}
                controlId="formSubject"
                className="row-subject"
              >
                <Form.Label column sm={4}>
                  Subject:
                </Form.Label>
                <Col sm={6} style={{ width: "70%" }}>
                  <Form.Control
                    rows={5}
                    maxLength="150"
                    as="textarea"
                    className="textarea"
                    onChange={(e) => handleChange(e,'emailSubject')}
                    disabled={
                      !caseFormConfig.find(
                        (c) => c.screeningDecision == sd_value
                      ).isAppealLevelActive
                    }
                    defaultValue={
                      caseDetailsAppeal?.emailSubject ||
                      caseFormConfig.find(
                        (c) => c.screeningDecision == sd_value
                      )?.appealEmailSubject
                    }
                  />
                </Col>

              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default AppealsCaseDetailAppeal;
