import { useOktaAuth } from "@okta/okta-react";
import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { homeApi } from "../API";
import { Container, Row, Col } from "react-bootstrap";
import Spinner from "../Spinner";
import HeaderName from "../HeaderName/HeaderName";
import AppealsCaseDetailInfo from "./AppealsCaseDetailInfo";
import AppealsCaseDetailContainer from "./AppealsCaseDetailContainer";
import "./AppealsDetails.css";
import axios from "axios";

const AppealsDetails = ({ name, routeId }) => {
  const [error, setError] = useState();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { authState, oktaAuth } = useOktaAuth();
  const [caseAppealLevels, setCaseAppealLevels] = useState([]);
  const [caseDetails, setCaseDetails] = useState();
  const [caseAdmitSources, setCaseAdmitSources] = useState([]);
  const [caseScreeningDecision, setCaseScreeningDecision] = useState([]);
  const [caseDenialReason, setCaseDenialReason] = useState([]);
  const [caseCollector, setCaseCollector] = useState([]);
  const [caseFormConfig, setCaseFormConfig] = useState([]);
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();

  const [userInfo, setUserInfo] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const cancelToken = axios.CancelToken.source();
      try {
        const res = await Promise.all([
          homeApi.getCaseDetail(id, accessToken, cancelToken),
          homeApi.getCaseAppealLevels(accessToken, cancelToken),
          homeApi.getCaseAdmitSources(accessToken, cancelToken),
          homeApi.getCaseScreeningDecision(accessToken, cancelToken),
          homeApi.getCaseDenialReason(accessToken, cancelToken),
          homeApi.getCaseCollector(accessToken, cancelToken),
          homeApi.getCaseFormConfig(id, accessToken, cancelToken),
        ]);
        setCaseDetails(res[0]);
        setCaseAppealLevels(res[1]);
        setCaseAdmitSources(res[2]);
        setCaseScreeningDecision(res[3]);
        setCaseDenialReason(res[4]);
        setCaseCollector(res[5]);
        setCaseFormConfig(res[6].data);
        setLoading(false);
      } catch {
        throw Error("Promise failed");
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="col loader mb-3">
          <Spinner />
        </div>
      ) : (
        <>
          <HeaderName name={name} routeId={routeId} />
          <Row
            className="justify-content-md-center"
            style={{
              paddingLeft: "10rem",
              paddingTop: "2rem",
              paddingRight: "10rem",
              width: '100%'
            }}
          >
            <Col xs lg="3" className="detail_info">
              <AppealsCaseDetailInfo caseDetails={caseDetails} />
              <Row></Row>
            </Col>
            <Col xs lg="9" className="p-0">
              <AppealsCaseDetailContainer
                caseDetails={caseDetails}
                caseAppealLevels={caseAppealLevels}
                caseAdmitSources={caseAdmitSources}
                caseScreeningDecision={caseScreeningDecision}
                caseDenialReason={caseDenialReason}
                caseCollector={caseCollector}
                caseFormConfig={caseFormConfig}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default AppealsDetails;
