import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { homeApi } from "../API";
import { Form, Row, Col } from "react-bootstrap";
import "./AppealsDetails.css";
import AppealsCaseDetailInfoTable from "./AppealsCaseDetailInfoTable";
import AppealsCaseDetailInfo from "./AppealsCaseDetailInfo";
import AppealCaseDetailUploadAttachments from "./AppealCaseDetailUploadAttachments";


const AppealsCaseDetailNotes = ({ caseDetailsAppeal,caseDetails, caseFormConfig, sd_value, handleChange, handleCase, uploadFileHandler, uploadedFiles }) => {


  return (
    <React.Fragment>
      <div className="table-case-info table-responsive" style={{overflow: 'hidden'}}>
        <h3 className="heading-denial">Notes & Attachments</h3>
        <Form>
          <Row className="row-appeals">
            <Col xs lg="6" className="p-0 comment">
              <Form.Group as={Row} className="row-appeals" controlId="formComments">
                <Form.Label column sm={4}>
                  Message:
                </Form.Label>
                <Col sm={6} className="comment-textarea">
                  <Form.Control
                    rows={5}
                    maxLength="500"
                    as="textarea"
                    className="textarea"
                    onChange={(e) => handleChange(e,'emailMessage')}
                    disabled={
                      !caseFormConfig.find(
                        (c) => c.screeningDecision == sd_value
                      )?.isMessageActive
                    }
                    defaultValue={
                      caseDetailsAppeal?.emailMessage ||
                      caseFormConfig.find(
                        (c) => c.screeningDecision == sd_value
                      )?.messageContent
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
            <AppealCaseDetailUploadAttachments
              attachment={false}
              uploadFileHandler={uploadFileHandler}
              uploadedFiles={uploadedFiles}
              caseDetails={caseDetails}
              disable={
                !caseFormConfig.find((c) => c.screeningDecision == sd_value)
                  .isMessageActive
              }
            handleUploadAttachment={handleCase}
            />
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default AppealsCaseDetailNotes;
