import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { homeApi } from "../API";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import "./AppealsDetails.css";
import AppealsCaseDetailInfoTable from "./AppealsCaseDetailInfoTable";
import AppealsCaseDetailAppeal from "./AppealsCaseDetailAppeal";
import AppealsCaseDetailScreening from "./AppealsCaseDetailScreening";
import AppealsCaseDetailNotes from "./AppealsCaseDetailNotes";
import { RolesContext } from "../context/RolesContext";
import axios from "axios";
import AlertModal from "../common/AlertModal";
const appealJSON = require("../data/appeal.json");

const AppealsCaseDetailContainer = ({
  caseDetails,
  caseAppealLevels,
  caseAdmitSources,
  caseScreeningDecision,
  caseDenialReason,
  caseCollector,
  caseFormConfig,
}) => {
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const { id } = useParams();
  const [sd_value, setSd_value] = useState(36);
  const [caseDetailsAppeal, setCaseDetailsAppeal] = useState(appealJSON);
  const [ip, setIP] = useState("");
  const roles = useContext(RolesContext);
  const [error, setError] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const userRoles = roles?.roles;
  let btnRef = useRef();

  const [fileLimit, setFileLimit] = useState(false);
  const [redirect, setRedirect] = useState({
    redirect: false,
    message: "",
    errorMessage: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxCount, setMaxCount] = useState(10);

  const uploadFileHandler = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    chosenFiles?.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === maxCount) setFileLimit(true);
        if (uploaded.length > maxCount) {
          setModalShow(true);
          // alert(`You can only add a maximum of ${maxCount} files`);
          setFileLimit(false);
          limitExceeded = true;
          if(e.target.files.length > 0) {
            e.target.value = null;
          }
          
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  useEffect(() => {
    // IP Address call
    // getData();
    if (caseDetails?.appeal !== null) {
      setCaseDetailsAppeal(caseDetails?.appeal);
    }
  }, []);

  // const getData = () => {
  //   homeApi.getData().then((data) => setIP(data.ip));
  // };

  const setCommentsSubjectMessage = (value) => {
    let configObj = caseFormConfig.find((x) => x.screeningDecision == value);
    setCaseDetailsAppeal((oldAppeal) => ({
      ...oldAppeal,
      emailSubject: configObj.appealEmailSubject,
      emailMessage: configObj.messageContent,
    }));
  };

  const handleChange = (e, propertyName) => {
    setRedirect({ redirect: false, message: "", errorMessage: "" });
    if (error === false) {
      btnRef.current.removeAttribute("disabled");
    }
    if (propertyName == "screeningDecisionDeficiencyCodeId") {
      setSd_value(e.target.value);
      setCommentsSubjectMessage(e.target.value);
    }
    if (propertyName !== "") {
      let value;
      if (
        propertyName == "emailUnnecessary" ||
        propertyName == "sucessUnlikely"
      ) {
        value = e.target.checked;
      } else
        value = propertyName.includes("Id")
          ? parseInt(e.target.value)
          : e.target.value;

      setCaseDetailsAppeal((oldAppeal) => ({
        ...oldAppeal,
        [propertyName]: value,
      }));
    }
  };


  const handleCase = (e) => {
    e.preventDefault();
    const cancelToken = axios.CancelToken.source();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }
    let data = new FormData();
    data.append("patientName", caseDetails?.PatientName);
    data.append("accountNumber", caseDetails?.Account);
    data.append("requestNumber", caseDetails?.requestNumber);
    uploadedFiles.forEach((file, i) => {
      data.append(`appealAttachments`, file);
    });
    for (const key in caseDetailsAppeal) {
      if (Object.keys(appealJSON).includes(key)) {
        if (caseDetailsAppeal[key] !== null) {
          if(key !== 'requestNumber') {
            data.append(key, caseDetailsAppeal[key]);
          }
        }
      }
    }
    setLoading(true);
    //Create new FormData object and append files
    homeApi
      .postCase(id, data, accessToken, cancelToken)
      .then((response) => {
        setLoading(false);
        setRedirect({ redirect: false, message: "success", errorMessage: "" });
        // setTimeout(() => {
        //   setRedirect({ redirect: true, message: "success", errorMessage: "" });
        // }, 1000);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setRedirect({
          redirect: false,
          message: "error",
          errorMessage: "Error in submitting the form, please try again!",
        });
      });
  };

  return (
    <React.Fragment>
      <AlertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={<>{`You can only add a maximum of `}<b>{maxCount}</b>{` files`}</>}
      ></AlertModal>
      {loading ? (
        <div className="beat-loader" style={{ textAlign: "center" }}>
          <Spinner size="sm" animation="border"></Spinner>
        </div>
      ) : (
        <></>
      )}
      {redirect.message === "success" ? (
        <>
          {window.scrollTo(0, 0)}
          <div className="alert alert-success" role="alert">
          <i className="fa fa-check-circle"></i>
            <span className="span-form">Form is successfully submitted!</span>
          </div>
        </>
      ) : redirect.message === "error" ? (
        <>
          {window.scrollTo(0, 0)}
          <div className="alert alert-error" role="alert">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span className="span-form">{redirect.errorMessage} </span>
          </div>
        </>
      ) : redirect.message ? (
        <>
          {window.scrollTo(0, 0)}
          <div className="alert alert-error" role="alert">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span className="span-form">{redirect.message} </span>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="form-appeal">
        <Row className="row-case">
          <Col className="detail_info_col">
            <AppealsCaseDetailInfoTable caseDetails={caseDetails} />
          </Col>
        </Row>
        <Row className="row-case mb-3">
          <Col>
            <AppealsCaseDetailScreening
              caseDetailsAppeal={caseDetailsAppeal}
              caseDetails={caseDetails}
              caseScreeningDecision={caseScreeningDecision}
              caseAdmitSources={caseAdmitSources}
              caseDenialReason={caseDenialReason}
              handleChange={handleChange}
              sd_value={sd_value}
            />
          </Col>
        </Row>
        <Row className="row-case mb-3">
          <Col>
            <AppealsCaseDetailAppeal
              caseDetailsAppeal={caseDetailsAppeal}
              caseAppealLevels={caseAppealLevels}
              caseCollector={caseCollector}
              caseFormConfig={caseFormConfig}
              sd_value={sd_value}
              handleChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="row-case">
          <Col>
            <AppealsCaseDetailNotes
              caseDetailsAppeal={caseDetailsAppeal}
              caseDetails={caseDetails}
              caseFormConfig={caseFormConfig}
              sd_value={sd_value}
              handleChange={handleChange}
              handleCase={handleCase}
              uploadedFiles={uploadedFiles}
              uploadFileHandler={uploadFileHandler}
            />
          </Col>
        </Row>
        <Button
          aria-controls="btn_search_drop"
          className="screening_submit"
          disabled={userRoles.role == "COLL" || caseDetails?.appeal?.screeningDecisionDeficiencyCode?.id == 6 ? true : false}
          onClick={handleCase}
          ref={btnRef}
        >
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AppealsCaseDetailContainer;
