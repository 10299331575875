import { useOktaAuth } from "@okta/okta-react";
import { useState, useEffect, useRef } from "react";
import { homeApi } from "../API";
import Spinner from "../Spinner";
import HeaderName from "../HeaderName/HeaderName";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import "./Users.css";
import axios from "axios";
import { Redirect, Link, useLocation } from "react-router-dom";

const typeOptions = require("../data/type.json");

const CreateNewUser = ({ name, routeId }) => {
  const [error, setError] = useState(false);
  let usersDefaultState = useLocation();
  const [data, setData] = useState(
    routeId == 9 ? usersDefaultState?.state?.user : {}
  );
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [redirect, setRedirect] = useState({
    redirect: false,
    message: "",
    errorMessage: "",
  });
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  let btnRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const cancelToken = axios.CancelToken.source();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }

    const postBody = {
      ...data,
    };
    homeApi
      .postCreateNewUser(postBody, accessToken, cancelToken)
      .then((res) => {
        setLoading(false);
        setRedirect({ redirect: false, message: "success", errorMessage: "" });
        if (routeId == 8) {
          setTimeout(() => {
            window.location.href = `/users`;
          }, 2000);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        if (e.response.status == 400) {
          setRedirect({
            redirect: false,
            message: "error",
            errorMessage: `${e.response.data
              .charAt(0)
              .toUpperCase()}${e.response.data.slice(1)}`,
          });
        } else {
          setRedirect({
            redirect: false,
            message: "error",
            errorMessage: "Error in submitting the form, please try again.",
          });
        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      });
  };
  const handleChange = ({ target: { name, value, checked } }) => {
    setRedirect({ redirect: false, message: "", errorMessage: "" });
    if (error === false) {
      btnRef.current.removeAttribute("disabled");
    }
    if (value != "Please make a selection" && name === "typeId") {
      setData({
        ...data,
        [name]: Number(value),
      });
    } else if (name == "admin" || name == "activeRecord") {
      setData({
        ...data,
        [name]: checked,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  return (
    <div>
      <Container maxWidth="lg">
        <h2 className="hn-header heading-home">{name}</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new-back" to={{ pathname: `/users` }}>
            Back
          </Link>
        </span>
        <hr className="hr"></hr>
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <div className="alert alert-success" role="alert">
              <i className="fa fa-check-circle"></i>
              {routeId == 8 ? (
                <span className="span-form">
                  User created successfully, redirecting to user list.
                </span>
              ) : (
                <span className="span-form">User modified successfully.</span>
              )}
            </div>
          </>
        ) : redirect.message === "error" ? (
          <>
            {window.scrollTo(0, 0)}
            <div className="alert alert-error" role="alert">
              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span className="span-form">{redirect.errorMessage} </span>
            </div>
          </>
        ) : (
          <></>
        )}
        <Col xs={6}>
          <Form onSubmit={handleSubmit} className="form-user">
            <Form.Group as={Row}>
              <Form.Label column>First Name *</Form.Label>
              <Form.Control
                className="form_control"
                name="firstName"
                required
                defaultValue={data.firstName}
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Middle</Form.Label>
              <Form.Control
                className="form_control"
                name="middle"
                defaultValue={data.middle}
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Last Name *</Form.Label>
              <Form.Control
                className="form_control"
                name="lastName"
                required
                defaultValue={data.lastName}
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Title</Form.Label>
              <Form.Control
                className="form_control"
                name="title"
                defaultValue={data.title}
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Email *</Form.Label>
              <Form.Control
                className="form_control"
                name="email"
                required
                defaultValue={data.email}
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column>Activity *</Form.Label>
              <Form.Control
                className="form_control"
                as="select"
                required
                name="typeId"
                onChange={handleChange}
                value={data.typeId}
              >
                {routeId == 9 ? (
                  typeOptions.map((value, i) => {
                    if (value.type == data.type) {
                      return (
                        <option key={i} value={value.typeId}>
                          {value.type}
                        </option>
                      );
                    } else {
                      return (
                        <>
                          <option key={i} value={value.typeId}>
                            {value.type}
                          </option>
                        </>
                      );
                    }
                  })
                ) : (
                  <>
                    <option value="">Please make a selection</option>
                    {typeOptions.map((value, i) => (
                      <option key={i} value={value.typeId}>
                        {value.type}
                      </option>
                    ))}
                  </>
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column lg={4}>
                Admin
              </Form.Label>
              <Col>
                <Form.Check
                  style={{ marginLeft: "25px" }}
                  className="activity_form_control"
                  type="checkbox"
                  defaultChecked={data.admin}
                  name="admin"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            {routeId == 9 ? (
              <Form.Group as={Row}>
                <Form.Label column lg={4}>
                  Active Record
                </Form.Label>
                <Col>
                  <Form.Check
                    style={{ marginLeft: "25px" }}
                    className="activity_form_control"
                    defaultChecked={data.activeRecord}
                    type="checkbox"
                    name="activeRecord"
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            ) : (
              <></>
            )}

            <Form.Group as={Row}>
              <Form.Label column lg={4}></Form.Label>
              <Col>
                <Button
                  aria-controls="btn_search_drop"
                  className="screening_submit create-btn"
                  type="submit"
                  ref={btnRef}
                >
                  {routeId == 9 ? "Save" : "Create"}
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </div>
  );
};

export default CreateNewUser;
