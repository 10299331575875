import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  useHistory,
  Routes,
  Switch,
  Route,
} from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import {
  Security,
  SecureRoute,
  LoginCallback,
  useOktaAuth,
} from "@okta/okta-react";
import { issuer, clientId, redirectUri, scopes } from "./config";
import Home from "./components/Home/Home";
import AllDenials from "./components/AllDenials/AllDenials";
import NavbarComponent from "./components/Navbar/Navbar";
import AppealsDetails from "./components/Appeals/AppealsDetails";
import CreateNewUser from "./components/UserManagement/CreateNewUser";
import RolesContextProvider from "./components/context/RolesContext";

import "./App.css";

const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: clientId,
  redirectUri: redirectUri,
  scopes: scopes,
});

const App = () => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="page-container">
        <RolesContextProvider>
          <NavbarComponent />
          <Switch className="container">
            <Route path="/" exact={true} component={Home} />
            <SecureRoute
              path="/alldenials"
              render={(props) => <AllDenials name="All Denials" routeId={1} />}
            />
            <SecureRoute
              path="/Appeals/Detail/:id"
              render={(props) => (
                <AppealsDetails name="Case Details" routeId={2} />
              )}
            />
            <SecureRoute
              path="/unscreened"
              render={(props) => (
                <AllDenials name="Unscreened Cases" routeId={3} />
              )}
            />
            <SecureRoute
              path="/screened"
              render={(props) => (
                <AllDenials name="Screened Cases" routeId={4} />
              )}
            />
            <SecureRoute
              path="/pending"
              render={(props) => (
                <AllDenials name="Pending Further Info" routeId={5} />
              )}
            />
            <SecureRoute
              path="/mycases"
              render={(props) => <AllDenials name="My Cases" routeId={6} />}
            />
            <SecureRoute
              path="/users"
              render={(props) => (
                <AllDenials name="User Management" routeId={7} />
              )}
            />
            <SecureRoute
              path="/user/create"
              render={(props) => (
                <CreateNewUser name="Create New User Account" routeId={8} />
              )}
            />
            <SecureRoute
              path="/user/edit/:id"
              render={(props) => (
                <CreateNewUser name="Edit User Account" routeId={9} />
              )}
            />
            <Route path="/implicit/callback" component={LoginCallback} />
          </Switch>
        </RolesContextProvider>
      </div>
    </Security>
  );
};

export default App;
