import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { homeApi } from "../API";
import { Form, Row, Col, Button } from "react-bootstrap";
import { RolesContext } from "../context/RolesContext";
import {
  getFormattedDate,
  getFormattedDateFromDate,
  getFormattedTimeFromDateTime,
} from "../common/DateHelper";
import "./AppealsDetails.css";
import AppealsCaseDetailInfoTable from "./AppealsCaseDetailInfoTable";
import AppealsCaseDetailInfo from "./AppealsCaseDetailInfo";

const AppealsCaseDetailScreening = ({
  caseDetailsAppeal,
  caseDetails,
  caseScreeningDecision,
  caseAdmitSources,
  caseDenialReason,
  handleChange,
  sd_value
}) => {
  const roles = useContext(RolesContext);
  const userRoles = roles?.roles;
  return (
    <React.Fragment>
      <div className="table-case-info table-responsive" style={{overflow: 'hidden'}}>
        <h3 className="heading-denial">Screening</h3>

        <Row className="row-appeals">
          <Col xs lg="6" className="p-0">
            <Form.Group as={Row} controlId="formScreeningDecision">
              <Form.Label column sm={4}>
                Screening Decision:
              </Form.Label>

              <Col sm={6}>
                <Form.Select
                  onChange={(e) => handleChange(e,'screeningDecisionDeficiencyCodeId')}
                  disabled = {userRoles?.role == "COLL" || caseDetails?.appeal?.screeningDecisionDeficiencyCodeId == 6 ? true : false}
                  value={
                    caseScreeningDecision?.find(
                      (c) =>
                        c.id ==
                        caseDetailsAppeal?.screeningDecisionDeficiencyCodeId
                    )?.id
                  }
                >
                  {caseScreeningDecision?.map((screeningDecision) => (
                    <option
                      key={screeningDecision?.id}
                      value={screeningDecision?.id}
                    >
                      {screeningDecision?.description}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            {sd_value == 50 ? (<>
              <Form.Group
              as={Row}
              controlId="formEmailCollectorNotNeeded"
              className="pt-2"
            >
              <Form.Label column sm={3}>
              Email to Collector Not Needed:
              </Form.Label>
              <Col sm={6} className="formEmailCollectorNotNeeded">
                <Form.Check
                  type="checkbox"
                  disabled = {userRoles?.role == "COLL" || caseDetails?.appeal?.screeningDecisionDeficiencyCodeId == 6 ? true : false}
                  defaultChecked={caseDetailsAppeal?.emailUnnecessary}
                  onChange={(e) => handleChange(e,'emailUnnecessary')}
                />
              </Col>
            </Form.Group>
              </>) : <></>}

           

            <Form.Group as={Row} controlId="formAdmitSource" className="pt-2">
              <Form.Label column sm={4}>
                Admit Source:
              </Form.Label>
              <Col sm={6} className="admitSourceId">
                <Form.Select
                onChange={(e) => handleChange(e,'admitSourceId')}
                disabled = {userRoles.role == "COLL" || caseDetails?.appeal?.screeningDecisionDeficiencyCodeId == 6 ? true : false}
                  value={
                    caseAdmitSources?.find(
                      (c) => c.id == caseDetailsAppeal?.admitSourceId
                    )?.id
                  }
                >
                  {caseAdmitSources?.map((admitSource) => (
                    <option key={admitSource?.id} value={admitSource?.id}>
                      {admitSource?.source}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              controlId="formReasonForDenial"
              className="pt-2"
            >
              <Form.Label column sm={4}>
                Reason for Denial:
              </Form.Label>
              <Col sm={6} className="reason-denial">
                <Form.Select
                onChange={(e) => handleChange(e,'denialReasonDeficiencyCodeId')}
                disabled = {userRoles.role == "COLL" || caseDetails?.appeal?.screeningDecisionDeficiencyCodeId == 6  ? true : false}
                  value={
                    caseDenialReason?.find(
                      (c) =>
                        c.id ==
                        caseDetailsAppeal?.denialReasonDeficiencyCodeId
                    )?.id
                  }
                >
                  {caseDenialReason?.map((denialReason) => (
                    <option key={denialReason?.id} value={denialReason?.id}>
                      {denialReason?.description}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
          <Col xs lg="6" className="comment">
            <Form.Group as={Row} controlId="formComments">
              <Form.Label column sm={4}>
                Comments:
              </Form.Label>
              <Col sm={6} style={{ width: "70%" }}>
                <Form.Control
                  rows={5}
                  maxLength="150"
                  as="textarea"
                  disabled = {userRoles?.role == "COLL" || caseDetails?.appeal?.screeningDecisionDeficiencyCodeId == 6  ? true : false}
                  className="textarea"
                  onChange={(e) => handleChange(e,'comments')}
                  defaultValue={caseDetailsAppeal?.comments}
                />
              </Col>
            </Form.Group>
          </Col>
         {Boolean(caseDetailsAppeal?.screenedByUser) ? <Col xs lg="7" className="p-0">
         <Form.Group
           as={Row}
           controlId="formScreenedBy"
           className="pt-2 screenedBy"
         >
           <Form.Label column sm="4">
             Screened By:
           </Form.Label>
           <Col sm="4" className="screened_readonly">
             <Form.Control
               plaintext
               readOnly
               value={`${caseDetailsAppeal?.screenedByUser?.firstName} ${caseDetailsAppeal?.screenedByUser?.lastName}`}
             />
           </Col>
         </Form.Group>
         <Form.Group
           as={Row}
           className="pt-2 screenedOn"
           controlId="formScreenedOn"
         >
           <Form.Label column sm="4">
             Screened On:
           </Form.Label>
           <Col sm="4" className="screened_readonly ">
             <Form.Control
               plaintext
               readOnly
               value={`${getFormattedDateFromDate(
                caseDetailsAppeal?.dateScreened?.split("T")[0]
               )} ${getFormattedTimeFromDateTime(
                caseDetailsAppeal?.dateScreened
               )}`}
             />
           </Col>
         </Form.Group>
       </Col> : <></>}
          
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AppealsCaseDetailScreening;
